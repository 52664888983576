/*----------------------------
Blog List  
----------------------------*/
.axil-post-wrapper {
    .content-blog {
        border-top: 1px solid #f3f3f3;
        padding-top: 60px;
        &:first-child {
            margin-top: 0 !important;
            border-top: none;
            padding-top: 0;
        }
    }
}


/* Main Blog  */

.content-blog {
    .thumbnail {
        margin-bottom: 30px;
        a {
            display: block;
            border-radius: var(--radius);
            img {
                width: 100%;
                border-radius: var(--radius);
            }
        }
    }
    .content {
        .title {
            font-weight: 500;
            line-height: 1.3;
            a {
                @extend %transition;
            }
        }
        p {
            margin-bottom: 30px;
        }
        .read-more-btn {
            .axil-btn {
                display: inline-flex;
            }
        }
    }
    &.sticky {
        .inner {
            background: #f9f3f0;
            border-left: 6px solid var(--color-tertiary);
            border-radius: var(--radius);
            padding: 50px;
            @media #{$small-mobile} {
                padding: 30px 15px;
            }
        }
    }
    &.format-quote {
        .inner {
            background: #f9f3f0;
            border-left: 6px solid var(--color-tertiary);
            border-radius: var(--radius);
            padding: 50px 40px 30px;
            @media #{$large-mobile} {
                padding: 30px 20px 10px;
            }
            .content {
                blockquote {
                    .title {
                        font-weight: 700;
                        line-height: 1.31;
                        font-size: 35px;
                        font-style: italic;
                        @media #{$sm-layout} {
                            font-size: 28px;
                        }
                        @media #{$large-mobile} {
                            font-size: 24px;
                        }
                        a {
                            @extend %transition;
                        }
                    }
                }
            }
        }
    }
    &.format-video {
        .thumbnail {
            position: relative;
            text-align: center;
            &:after {
                content: "";
                height: 100%;
                width: 100%;
                background-color: var(--color-black);
                opacity: .2;
                border-radius: 4px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
            .popup-video {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                z-index: 2;
                .play-btn {
                    height: 150px;
                    width: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(0,0,0,.8);
                    border-radius: 50%;
                    margin: 0 auto;
                    font-size: 32px;
                    color: var(--color-white);
                    transition: var(--transition);
                    &:hover {
                        background-color: rgba(0,0,0,1);;
                    }
                    @media only screen and (max-width: 767px) {
                        height: 80px;
                        width: 80px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
    // Post Sidebar 
    &.post-list-view {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 20px;
        @media #{$small-mobile} {
            align-items: flex-start;
        }
        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
            padding-bottom: 0;
        }
        .thumbnail {
            width: 120px;
            margin-right: 20px;
            min-width: 120px;
            overflow: hidden;
            margin-bottom: 0;
            @media #{$lg-layout} {
                width: 70px;
                margin-right: 10px;
                min-width: 70px;
            }
            @media #{$small-mobile} {
                width: 80px;
                min-width: 80px;
            }
            a {
                border-radius: 6px;
                overflow: hidden;
                img {
                    width: 100%;
                    border-radius: 6px;
                    transition: 0.5s;
                    object-fit: contain;
                }
            }
        }
        .content {
            flex: 1;
            .title {
                font-size: 17px;
                margin-bottom: 10px;
                @media #{$sm-layout} {
                    font-size: 16px;
                }
                a {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .axil-post-meta {
                margin-bottom: 0;
            }
        }
        &:hover {
            .thumbnail {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

/*---------------------
    Blog Meta 
-----------------------*/

.axil-post-meta {
    display: flex;
    margin-bottom: 20px;
    .post-author-avatar {
        min-width: 50px;
        max-height: 50px;
        margin-right: 20px;
        width: 50px;
        img {
            border-radius: 100%;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .post-meta-content {
        .author-title {
            margin-bottom: 5px;
            font-size: 16px;
            a {
                @extend %transition;
            }
        }
    }
}

.post-meta-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    margin: 0 -15px;
    li {
        color: var(--color-body);
        font-size: 14px;
        padding: 0 15px;
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        &::after {
            position: absolute;
            content: "";
            background: #CBD3D9;
            width: 1px;
            height: 14px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

/*---------------------
    Blog Grid 
-----------------------*/
.blog-grid {
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    padding: 20px;
    .thumbnail {
        margin-bottom: 25px;
        overflow: hidden;
        border-radius: 6px;
        position: relative;
        img {
            transition: .5s;
        }
        .blog-category {
            position: absolute;
            bottom: 20px;
            right: 20px;
            a {
                background-color: rgba(255, 255, 255, 0.5);
                border: 1px solid rgba(255, 255, 255, 0.5);
                backdrop-filter: blur(25px);
                box-shadow: 0 4px 30px rgba(0, 0, 0,.1);
                padding: 2px 10px;
                border-radius: 4px;
                color: var(--color-white);
                font-size: 14px;
            }
        }
    }
    .content {
        .title {
            margin-bottom: 20px;
        }
        .axil-btn {
            padding: 0;
            align-items: center;
            color: var(--color-heading);
            i {
                padding-left: 6px;
                top: 1px;
                color: var(--color-heading);
                transition: var(--transition);
            }
            &:after {
                content: "";
                height: 1px;
                width: 0;
                background-color: var(--color-primary);
                position: absolute;
                bottom: 0;
                right: 0;
                transition: var(--transition);
            }
            &:hover {
                color: var(--color-primary);
                &:after {
                    width: 100%;
                    left: 0;
                }
                i {
                    color: var(--color-primary);
                }
            }
        }
    }
    &:hover {
        .thumbnail {
            img {
                transform: scale(1.1);
            }
        }
    }
}