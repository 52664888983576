/*-------------------------
Video Style
--------------------------*/

.video-banner {
    position: relative;
    &:after {
        content: "";
        height: 100%;
        width: 100%;
        background: radial-gradient(circle, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
        border-radius: 6px;
        position: absolute;
        top: 0;
        bottom: 00;
        left: 0;
        right: 0;
    }
    img {
        width: 100%;
        border-radius: 6px;
    }
    .popup-video-icon {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        z-index: 1;
        .video-icon {
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: var(--color-white);
            border-radius: 50%;
            display: inline-block;
            font-size: 24px;
            color: var(--color-primary);
            position: relative;
            z-index: 1;
            i {
                position: relative;
                left: 2px;
                transition: var(--transition);
            }
            &:after {
                content: "";
                height: 100%;
                width: 100%;
                background-color: var(--color-white);
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                transition: var(--transition);
            }
            &:hover {
                i {
                    transform: scale(.9);
                }
                &:after {
                    transform: scale(1.1);
                }
            }
        }
    }
}

